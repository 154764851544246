<script>
    import Accordion from "@/components/Accordion";
    import PaymentIcons from "@/components/PaymentIcons";

    export default {
        name: "PaymentContent",
        components: {
            PaymentIcons,
            Accordion,
        },
        props: {
            text: Array,
        },
        data() {
            return {
                activeIndex: 0,
            };
        },
        methods: {
            changeIndex(index) {
                this.activeIndex = index;
            },
        },
    };
</script>

<template>
    <div class="b-text-page">
        <div class="b-text-delivery">
            <h2>Доставка</h2>
            <div class="b-text-delivery__content">
                <div class="b-text-delivery__accordion">
                    <accordion
                        :active-index="activeIndex"
                        :index="i"
                        @change="changeIndex"
                        :accordion="item"
                        v-for="(item, i) in text"
                        :key="i + 10"
                    >
                    </accordion>
                </div>
            </div>
            <div class="b-text-delivery__free"></div>
        </div>
        <div class="b-text-payment" id="payment">
            <h2>Оплата</h2>

            <div class="b-text-payment__content">
                <div class="b-text-payment__title">
                    К&nbsp;оплате принимаются наличные и&nbsp;банковские карты МИР, Visa,
                    MasterCard.
                </div>
                <div class="b-text-payment__text">
                    Оплата банковскими картами производится на&nbsp;сайте через платежный
                    сервис Payler, расчет наличными возможен непосредственно с&nbsp;курьером
                    в&nbsp;момент доставки.
                </div>
                <div class="b-text-payment__icons">
                    <payment-icons></payment-icons>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "~@/assets/sass/service-page.scss";
</style>
