<script>
    import { metaInfo } from "@/core/MetaInfo";
    import PaymentContent from "@/components/PaymentContent";
    import TypographyWrapper from "@/components/TypographyWrapper";
    import GuaranteeList from "@tb/components/GuaranteeList";

    export default {
        name: "Payment",
        components: {
            PaymentContent,
            TypographyWrapper,
            GuaranteeList,
        },
        data() {
            return {
                text: [
                    {
                        title: "Доставка по Москве, Санкт-Петербургу и Екатеринбургу",
                        desc: [
                            "Доставка по&nbsp;Москве, Санкт-Петербургу (в&nbsp;пределах КАД) и&nbsp;Екатеринбургу (в&nbsp;пределах ЕКАД) осуществляется ежедневно с&nbsp;10:00 до&nbsp;20:00. При оформлении заказа до&nbsp;17:30 доставка осуществляется на&nbsp;следующий день.",
                            "В&nbsp;Москве и&nbsp;Санкт-Петербурге также работает срочная экспресс-доставка в&nbsp;день оформления заказа. Стоимость услуги 500&nbsp;рублей. Для оформления услуги экспресс-доставки необходима полная предоплата заказа картой, т.к. услуга осуществляется силами сторонних курьерских служб.",
                            "Услуга предоставляется в&nbsp;пределах МКАД/КАД. Возможна экспресс-доставка в&nbsp;ближайшее Подмосковье и&nbsp;Ленобласть, для расчета стоимости доставки необходимо обращаться к&nbsp;менеджерам.",
                            "Курьер выезжает с&nbsp;заказом после предварительного согласования адреса и&nbsp;указанного покупателем номера телефона с&nbsp;менеджером интернет-магазина и&nbsp;связывается за&nbsp;30-60 минут до&nbsp;приезда.",
                        ],
                    },
                    {
                        title: "Самовывоз в Москве, Санкт-Петербурге и Екатеринбурге",
                        desc: [
                            "Заказы нашего интернет-магазина можно забрать в&nbsp;день заказа в&nbsp;магазинах-партнерах:",
                            "г. Москва, Бутырский Вал д.&nbsp;32, магазин гаджетов Madrobots",
                            "г. Москва, 1-я Магистральная&nbsp;ул.&nbsp;д.&nbsp;18, магазин гаджетов Madrobots",
                            "г. Санкт-Петербург, Лиговский проспект&nbsp;д. 3/9, магазин гаджетов Madrobots",
                            "г. Екатеринбург, ул. Горького 33А, магазин гаджетов Madrobots",
                        ],
                    },
                    {
                        title: "Доставка в Подмосковье",
                        desc: [
                            "В&nbsp;Московскую область осуществляется доставка такими службами, как: Стриж, СДЭК, DPD, Boxberry и&nbsp;PickPoint.",
                            "Точная стоимость доставки и&nbsp;ее&nbsp;срок автоматически рассчитывается при заказе товара и&nbsp;указывается в&nbsp;корзине в&nbsp;зависимости от&nbsp;удаленности населенного пункта от&nbsp;логистических центров.",
                            "Вы&nbsp;можете получить более подробную информацию у&nbsp;менеджера интернет-магазина при согласовании заказа или у&nbsp;специалиста технической поддержки в&nbsp;онлайн-чате в&nbsp;правом нижнем углу экрана.",
                        ],
                    },
                    {
                        title: "Доставка в регионы Российской Федерации",
                        desc: [
                            "Доставка за&nbsp;пределы Москвы, Санкт-Петербурга и&nbsp;Екатеринбурга осуществляется силами курьерских служб СДЭК, DPD, Boxberry, Pickpoint, Почта России.",
                            "Для оформления заказа необходимо добавить товар в&nbsp;корзину и&nbsp;указать населенный пункт. Службу и&nbsp;способ доставки можно выбрать самостоятельно из&nbsp;предложенного списка. Автоматически из&nbsp;всех доступных вариантов предлагается самый оптимальный по&nbsp;скорости и&nbsp;стоимости на&nbsp;основании тарифной сетки курьерских компаний.",
                        ],
                    },
                    {
                        title: "Условия бесплатной доставки",
                        desc: ["Бесплатная доставка по России при заказе от 5000 рублей."],
                    },
                    {
                        title: "Доставка в пункты выдачи заказов на территории РФ",
                        desc: [
                            "В&nbsp;большинстве городов России можно воспользоваться услугой самовывоза из&nbsp;пункта выдачи курьерской компании. Данная услуга значительно выгоднее прямой доставки и&nbsp;стоит от&nbsp;180 до&nbsp;1000 рублей в&nbsp;зависимости удаленности населенного пункта от&nbsp;логистических центров.",
                        ],
                    },
                ],
            };
        },
        metaInfo() {
            return metaInfo.getMeta("delivery");
        },
    };
</script>

<template>
    <div class="l-container">
        <typography-wrapper>
            <payment-content :text="text"></payment-content>
        </typography-wrapper>

        <div class="b-text-page__guarantee">
            <guarantee-list></guarantee-list>
        </div>
    </div>
</template>
